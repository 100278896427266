<template>
  <div>
    <div>
      <h2>Такой страницы не существует :(</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  metaInfo: {
    title: 'Страница не найдена',
    meta: [
      { name: 'description', content: 'Страница не существует' },
    ],
  },
};
</script>
