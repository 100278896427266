<template>
  <div class="contacts-block">
    <BannerTruthOrDare />
    <br/>
    <br/>
    <div style="display: flex; justify-content: center; flex-wrap: wrap;">
      <router-link style="color: white; margin: 0px 10px; link" :to="{name: 'Guide'}">Как распаковать архив</router-link>
      <router-link style="color: white; margin: 0px 10px;" :to="{name: 'Terms'}">Условия использования</router-link>
      <router-link style="color: white; margin: 0px 10px;" :to="{name: 'Privacy'}">Политика конфиденциальности</router-link>
      <router-link style="color: white; margin: 0px 10px;" :to="{name: 'Contacts'}">Контакты</router-link>
    </div>
    <div style="margin-top: 20px">
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <!-- <a href="https://www.webmoney.ru/" target="_blank" style="margin: 0px 8px"><img src="https://www.webmoney.ru/img/icons/88x31_wm_blue.png"/></a> -->
        <img src="@/assets/img/payment system logos.png" style="margin: 0px 8px"/>
      </div>
      <p class="copyright">© cssoftshop.ru, 2020-2024</p>
    </div>
  </div>
</template>

<script>
import BannerTruthOrDare from '@/components/BannerTruthOrDare.vue';

export default {
  name: 'Footer',
  components: { BannerTruthOrDare },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.contacts-block
  display: flex
  flex-direction: column
  align-items: center
  margin: 20px

.contact-mail, .vk-link
  color: white
  font-size: 16px

.copyright
  margin-top: 15px
  padding: 0px
  color: gray
  font-size: 14px
  margin-bottom: 16px
</style>
