<template>
<div class="terms">
    <div class="description" v-html="pageText"></div>
  </div>
</template>

<script>
import PageHTML from '@/views/html/contacts.html';

export default {
  name: 'Contacts',
  metaInfo: {
    title: 'ВХ для КС - Контакты',
    link: [
      { rel: 'canonical', href: 'https://cssoftshop.ru/contacts' },
    ],
    meta: [
      { name: 'description', content: 'Контактная информация' },
    ],
  },
  data() {
    return {
      pageText: PageHTML,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

.description
    color: white
    text-align: left
    max-width: 900px
    margin: 0px auto
    -webkit-user-select: none
    -ms-user-select: none
    user-select: none
</style>
