<template>
<div style="width: 900px">
  <div class="buy-key">
    <div>
      <h1>Оформить подписку</h1>
    </div>
      <div>
      <SubscriptionType style="margin: 24px 0px 0px 0px"
          :list=subscriptionTypes
          :selectedId='selectedSubscriptionTypeId'
          @select='selectSubscriptionTypeHandler'
        />
      </div>
      <div v-if="uuidValue" class="form-container">
        <div>
          <div class="input-label">Ваш ID (обязательное поле)</div>
          <input class="input uuid-input" readonly type="text" autocomplete="off" placeholder="Ваш ID" v-model="uuidValue"/>
        </div>
        <div>
          <div class="input-label">Email для чека (обязательное поле)</div>
          <input
            class="input uuid-input"
            type="email"
            placeholder="Email для отправки чека"
            v-model="email"
            />
        </div>
        <!-- <div>
          <input class="input promo-code-input" type="text" autocomplete="off" placeholder="Promo code" v-model.trim="promoCode"/>
        </div> -->
        <div style="margin-top: 12px">
          <div v-if="!isLoadingLink" class="btn-section">
            <p><b>Варианты оплаты:</b></p>
            <Button
              v-if="uuidValue.length > 1 && this.email"
              text="СБП (walletone)"
              @click="getCardPayment"
            />
            <Button
              v-if="uuidValue.length > 1 && this.email"
              :disabled="selectedSubscriptionTypeId < 2"
              text="Крипта (TRC-20)"
              @click="() => { getFreekassaPayment(15);}"
            />
            <!-- <Button
              v-if="uuidValue.length > 1 && this.email"
              :disabled="selectedSubscriptionTypeId < 2"
              text="KZT (ПЕРЕВОД НА КАРТУ)"
              @click="() => { getFreekassaPayment(41);}"
            /> -->
          </div>
          <i v-else class="el-icon-loading loader"/>

          <div style="margin-top: 18px">
            <p>
              <b>Примечание:</b> ID привязывается к железу ПК и Windows!
              При замене комплектующих ПК или переустановки Windows, ID может измениться.
              Производите обновление ПК или переустановку Windows после окончания подписки.<br />
            </p>
            <p class="warning">
              Продавец не несет ответственности в случае утери доступа к подписке из-за смены вашего ID.
              Нажимая кнопку "Купить", Вы соглашаетесь как с вышеизложенным так и с
              <router-link class="link" title="условия использования" :to="{name: 'Terms'}">условиями использования</router-link>
              и берете всю ответственность на себя.
            </p>
          </div>
        </div>
      </div>
  </div>

  <div v-if="!uuidValue" class="description section">
    <h2>КАК ОФОРМИТЬ ПОДПИСКУ?</h2>
    <p>
    Скачайте с главной страницы сайта программу, запустите её и нажмите кнопку "Купить".<br/>
    Откроется страница браузера, куда автоматически подставится ваш ID. Выберите вариант подписки и укажите свою почту.<br/>
    </p>
  </div>
  <!-- <div class="description section" style="max-width: 100%">
    <h2>ПАРТНЕРЫ:</h2>
    <a href="https://play.google.com/store/apps/details?id=com.krayud.only_true"
      class="truth-or-dare" target="_blank" title="Мобильная игра Правда или действие">
      <img style="max-width: 100%" src="@/assets/img/truth_or_dare_logo.jpg" />
    </a>
  </div> -->
</div>
</template>

<script>
import SubscriptionType from '@/components/SubscriptionType.vue';
import Button from '@/components/Button.vue';
import { isDeprecatedWindows } from '@/utils/utils';

export default {
  name: 'BuyKey',
  components: {
    SubscriptionType,
    Button,
  },
  metaInfo: {
    title: 'ВХ для КС - купить подписку',
    meta: [
      { name: 'description', content: 'Купить подписку на ВХ чит для cs2 по доступной цене.' },
    ],
    link: [
      { rel: 'canonical', href: 'https://cssoftshop.ru/buykey' },
    ],
  },
  data() {
    return {
      isBuyState: false,
      isLoadingLink: false,
      uuidValue: this.$route.params.uuid || '',
      promoCode: '',
      email: null,
      subscriptionTypes: [
        {
          id: 1,
          title: '14 дней',
          price: 329,
          price_usd: 3.6,
        },
        {
          id: 2,
          title: '30 дней',
          price: 500,
          price_usd: 5.5,
        },
        {
          id: 3,
          title: '60 дней',
          price: 900,
          price_usd: 9.8,
        },
      ],
      selectedSubscriptionTypeId: 2,
    };
  },
  computed: {
  },
  created() {
    const emailFromLocalStorage = localStorage.getItem('user_email');
    if (emailFromLocalStorage) this.email = emailFromLocalStorage;
  },
  methods: {
    validEmail(email) {
      // eslint-disable-next-line max-len
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getCardPayment() {
      this.getPayUrl('card');
    },

    getEnotPayment() {
      this.getPayUrl('enot');
    },

    getFreekassaPayment(paymentMethodId) {
      this.getPayUrl('freekassa', paymentMethodId);
    },

    getCryptPayment() {
      this.getPayUrl('crypt');
    },

    getPayUrl(paymentType, paymentMethodId) {
      if (false && isDeprecatedWindows()) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Для корректной работы программы требуется Windows 10 и выше',
        });
        return;
      }

      if (!this.validEmail(this.email)) {
        this.$notify.error({
          title: 'Введите корректный email',
          message: 'email необходим для отправки чеков',
        });
        return;
      }
      localStorage.setItem('user_email', this.email);

      this.isLoadingLink = true;
      this.$api.getPaymentData({
        uuid: this.uuidValue,
        promoCode: this.promoCode,
        subscriptionType: this.selectedSubscriptionTypeId,
        email: this.email,
        type: paymentType,
        paymentMethodId,
      })
        .then((response) => {
          this.isLoadingLink = false;
          if (response.data.status === 'error') {
            this.$notify.error({
              title: 'Ошибка сети',
              message: response.data.error,
            });
            this.isLoadingLink = false;
            return;
          }

          if (response.data.paymentForm) {
            if (response.data.type === 'crypt' || response.data.type === 'freekassa') {
              window.open(response.data.paymentForm, '_blank');
            } else {
              const div = document.createElement('div');
              div.innerHTML = response.data.paymentForm.trim();
              const form = div.firstChild;
              document.body.appendChild(form);
              form.submit();
              form.remove();
            }
          }
        })
        .catch(() => {
          this.isLoadingLink = false;
          this.$notify.error({
            title: 'Ошибка сети',
            message: 'Не удалось получить ссылку для оплаты',
          });
        });
    },
    selectSubscriptionTypeHandler(id) {
      this.selectedSubscriptionTypeId = id;
    },
  },
  mounted() {
    // this.$api.openPaymentPage({ uuid: this.uuidValue });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.buy-key
  width: 100%

.form-container
  margin: 16px 0px 32px 0px

.input
  -webkit-appearance: none
  background-color: rgba(30, 30, 30, 0.8)
  background-image: none
  border-radius: 4px
  border: 1px solid #183485
  box-sizing: border-box
  color: #ffffff
  display: inline-block
  font-size: inherit
  height: 40px
  line-height: 40px
  outline: 0
  padding: 0 15px
  width: 100%
  font-family: 'Montserrat', sans-serif
  -webkit-font-smoothing: antialiased

.uuid-input, .promo-code-input
  margin: 8px 0px 16px 0px
  min-width: 200px
  max-width: 480px

.input-label
  font-size: 12px
  color: #fcfcfc
.description
  text-align: left

.demo-text
  color: #ff6130

.warning
  font-size: 12px;
  text-align: center;

a
  color: #ff6130 !important
  text-decoration: none
  &:hover
    text-decoration: underline

.truth-or-dare
  opacity: 0.8
  transition: opacity 0.15s ease-in-out

  &:hover
    opacity: 1

.loader
  color: #ff6130
  font-size: 36px
  font-weight: bold
.btn-section
  margin: 0px auto
  max-width: 450px
  flex-direction: column
  display: flex
  gap: 10px
  flex-wrap: wrap
  justify-content: center
</style>
