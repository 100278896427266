import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import BuyKey from '../views/BuyKey.vue';
import BuySuccess from '../views/BuySuccess.vue';
import Terms from '../views/Terms.vue';
import Contacts from '../views/Contacts.vue';
import Privacy from '../views/Privacy.vue';
import Guide from '../views/Guide.vue';
import Faq from '../views/Faq.vue';

import NotFoundPage from '../views/NotFoundPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/buykey/:uuid',
    name: 'BuyKey',
    component: BuyKey,
  },
  {
    path: '/buykey',
    name: 'BuyKey',
    component: BuyKey,
  },
  {
    path: '/buysuccess',
    name: 'BuySuccess',
    component: BuySuccess,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
  // {
  //   path: '/howtobuy',
  //   name: 'HowToBuy',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
