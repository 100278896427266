<template>
<div class="buy-success">
    <div>
      <h1>Спасибо за покупку!</h1>
    </div>
    <div class="description">
      <p>
        Вы можете начать пользоваться программой.<br/>
        Если возникли проблемы - обращайтесь к нам на почту <b>info@cssoftshop.ru</b> или в <a href="https://vk.com/public200164769">группу ВК</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuySuccess',
  metaInfo: {
    title: 'ВХ для КС - Подписка на чит оформлена',
  },
  meta: [
    { name: 'description', content: 'Успешная покупка' },
  ],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

.description
  a
    color: white
</style>
