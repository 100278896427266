<template>
  <div class="radar-buy">
    <div>
      <div class="title">
        <h1>ВХ для КС2 / Wallhack для CS2</h1>
      </div>
      <div class="description">
        <h2>
          Здесь вы можете бесплатно скачать вх для кс без регистрации!
          <br/><span class="demo-text"><b>1</b> день демо периода для новых пользователей</span>
        </h2>
        <p>
          Наш чит (ВХ) содержит только самый нужный функционал и не использует оверлеев.
          <b class="demo-text">Предназначен для тренировочных игр с компьютерными противниками (ботами).</b><br />
          <br />
          Обязательно ознакомьтесь с инструкциями по установке и запуску.<br />
          После окончания демо периода, вы можете выбрать удобный для себя вариант подписки. <b>Подписка привязывается к железу ПК.</b>
        </p>
      </div>
      <div class="btn-section">
        <div>
          <Button text="СКАЧАТЬ ВХ CS2 (v2.0.12)" @click="checkBeforeDownload" />
        </div>
        <router-link title="Купить подписку на вх для кс2" to="/buykey">
          <Button text="ПОДПИСКА" />
        </router-link>
      </div>
      <div class="demo-text navigation">
        <router-link :to="{ name: 'Guide' }" title="Как установить ВХ">Как установить</router-link>
        <a href="#guide" title="Как запустить ВХ">Как запустить</a>
        <router-link :to="{ name: 'Faq' }" title="Возможные ошибки при запуске ВХ">Ошибки при запуске</router-link>
      </div>
    </div>

    <div style="display: flex; justify-content: center;">
      <iframe
        width="100%"
        height="400"
        src="https://www.youtube.com/embed/gTjo27jawcE"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
    <div class="section advertisers">
      <h2>НАШИ ПРЕИМУЩЕСТВА:</h2>
      <ul>
        <li>Без регистрации</li>
        <li>Не нужно устанавливать никакие программы. Достаточно скачать .exe файл в архиве</li>
        <li>Вам не нужно снова скачивать или обновлять клиент после обновлений КС</li>
        <li>Используем только самый нужный функционал, который поможет натренироваться в игре с ботами</li>
        <li>Не используем оверлеи, что положительно сказывается на производительности</li>
        <li>Своевременные обновления и поддержка актуальной версии КС</li>
        <li>Большой выбор вариантов подписки</li>
      </ul>
    </div>
    <div class="section guide">
      <h2 id="guide">КАК ЗАПУСТИТЬ ВХ:</h2>
      <p><b>*Проверено на Windows 10/11 (на Windows 7 работа не гарантируется)</b></p>
      <p>1.
        <router-link :to="{ name: 'Guide' }">
          Распаковать архив с программой
        </router-link>
        , запустить сначала <b>CS2</b> затем <b>ВХ</b>
        <br/>
        2.В настройках игры (<span class="demo-text"><b>ИЗОБРАЖЕНИЕ &#129046; ГРАФИКА</b></span>)
        выставить <span class="demo-text"><b>"ПОЛНОЭКРАННЫЙ В ОКНЕ"</b></span>
        или <span class="demo-text"><b>"В ОКНЕ"</b></span>
      </p>
      <div style="margin-top: 10px; text-align: center;">
        <img class="gui_example" alt="Чит ВХ для кс 2 и его интерфейс" title="Интерфейс чита" src="../assets/img/cs2_cheat_v_2_0.png" />
      </div>
      <p>3. Создать и запустить игру с ботами и после загрузки нажать "Включить"</p>
      <div style="margin-top: 10px; text-align: center;">
        <img
          class="esp_example"
          alt="Пример работы ВХ кс 2 - подсветка уровня HP"
          title="Пример работы чита cs go - подсветка уровня HP"
          src="../assets/img/cs2_esp.png"
        />
      </div>
      <div style="margin-top: 10px; text-align: center;">
        <img class="esp_example" alt="Пример работы чита кс 2" title="Пример работы чита cs go" src="../assets/img/cs2_esp settings.png" />
      </div>
      <p>После этого, в любой момент можно отключать и включать ВХ (без перезапуска CS)</p>
      <p>
        <b>Важно:</b> если чит включен, но эффекта не наблюдается, возможно, произошло обновление CS.<br />
        Необходимо подождать, пока мы обновим наш сервер и запустить чит еще раз. Приятной игры :)
      </p>
      <p>
        Проверить обновления или задать вопрос в тех. поддержку можно в
        <a target="_blank" href="https://vk.com/cssoftshop" title="Группа ВК">группе ВК</a>
      </p>
      <h2>ВИДЕОИНСТРУКЦИЯ:</h2>
      <video width="100%" height="auto" controls>
        <source src="@/assets/video/guide.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
    <!-- <div class="section news">
      <h2>НОВОСТИ И ОБНОВЛЕНИЯ:</h2>
      <div id="vk_groups" />
    </div> -->
    <!-- <div class="section">
      <h2>ПАРТНЕРЫ:</h2>
      <a href="https://play.google.com/store/apps/details?id=com.krayud.only_true"
        class="truth-or-dare" target="_blank" title="Мобильная игра Правда или действие">
        <img style="width: 100%" src="@/assets/img/truth_or_dare_logo.jpg" />
      </a>
    </div> -->
    <el-dialog
      :visible.sync="centerDialogVisible"
      title="Соглашение"
      append-to-body
      :lock-scroll="false"
      :destroy-on-close="true"
      class="window-style"
      @closed="downloadWindowClosed"
      center
    >
      <p class="demo-text" style="word-break: break-word;">
        Программа предназначена для тренировочных игр с компьютерными противниками (ботами). Используя её, вы соглашаетесь с этим условием и подтверждаете, что
        прочли и принимаете <a target="_blank" style="color: white;" href="https://cssoftshop.ru/terms">Условия использования</a> <br />
        При передаче программы другим лицам, вы должны ознакомить их с условиями использования.
      </p>
      <br />
      <el-checkbox v-model="iAgree" class="agree_checkbox">Я согласен со всеми условиями использования</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" :loading="isDownloading" @click="downloadFile" :disabled="!iAgree">Скачать</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import Button from '@/components/Button.vue';
import { isDeprecatedWindows } from '@/utils/utils';

export default {
  name: 'Home',
  components: {
    Button,
  },
  metaInfo: {
    title: 'Скачать ВХ для КС 2 / WH для CS2',
    meta: [{ name: 'description', content: 'Чит ВХ для КС2 (CS2). Простой в использовании, невидим для VAC.' }],
    link: [{ rel: 'canonical', href: 'https://cssoftshop.ru' }],
  },
  data() {
    return {
      centerDialogVisible: false,
      isDownloading: false,
      iAgree: false,
    };
  },
  methods: {
    checkBeforeDownload() {
      if (false && isDeprecatedWindows()) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Для корректной работы программы требуется Windows 10 и выше',
        });
      } else {
        this.centerDialogVisible = true;
      }
    },
    downloadFile() {
      this.isDownloading = true;
      axios({
        url: 'https://cssoftshop.ru/downloads/cssoftshop_v2_0_12.zip',
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cssoftshop_v2_0_12.zip');
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    downloadWindowClosed() {
      this.iAgree = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.radar-buy
  text-align: left
  max-width: 900px
  .title
    text-align: center
    margin-bottom: 20px
.btn-section
  margin: 30px 0px 30px 0px
  display: flex
  justify-content: space-evenly
  flex-wrap: wrap
  gap: 10px
.demo-text
  color: #ff6130
  a
    color: #ff6130

.esp_example
  width: 450px
  max-width: 100%

.gui_example
  width: 296px

.image-caption
  font-size: 12px

.advertisers
  color: white
  li
    margin-left: 30px
    color: white
    font-size: 18px
    line-height: 1.5
    &::marker
      content: '👍 '

/deep/ .el-dialog
  background-color: #1e1e1e
  border-radius: 10px
/deep/ .el-dialog__title
  color: white

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background-color: #67C23A
    border-color: #67C23A

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label
  color: #67C23A

/deep/ .el-icon-close
  &:hover
    color: #ff8000

.truth-or-dare
  opacity: 0.8
  transition: opacity 0.15s ease-in-out

  &:hover
    opacity: 1

.agree_checkbox
  display: flex
  align-items: center
  /deep/ .el-checkbox__label
    white-space: normal

.navigation
  font-size: 18px
  font-weight: bold
  margin-bottom: 30px
  display: flex
  justify-content: center
  flex-wrap: wrap
  align-content: space-between
  gap: 10px

.window-style
  max-width: 100%
  /deep/ .el-dialog
    max-width: 600px
    width: 90%
    min-width: 320px
</style>
