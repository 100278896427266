<template>
  <div class="terms">
    <h1>Возможные ошибки при запуске</h1>
    <br />
    <img class="guide_archive" alt="Ошибка 1" title="Ошибка 1" src="../assets/img/possible_errors/1.png" />
    <p class="description">
      1. Нужно распаковать архив с программой. И все файлы из архива должны лежать рядом! Как это сделать см. раздел
      <router-link :to="{ name: 'Guide' }">как установить</router-link>
    </p>
    <img class="guide_archive" alt="Ошибка 2" title="Ошибка 2" src="../assets/img/possible_errors/2.png" />
    <p class="description">
      2. Означает, что установлены сторонние модификации для CS (скинченджеры и т.п.) или запущены другие программы, связанные с ней. Работает только с "чистой"
      CS.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  metaInfo: {
    title: 'ВХ для КС - Возможные ошибки',
    link: [{ rel: 'canonical', href: 'https://cssoftshop.ru/faq' }],
    meta: [{ name: 'description', content: 'Возможные ошибки при запуске' }],
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.description
  color: white
  text-align: left
  max-width: 900px
  margin: 10px auto

.guide_archive
  max-width: 100%
  height: auto
</style>
