<template>
  <div id="app">
    <div class="header">
      <Logo/>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <div class="footer">
        <Footer/>
    </div>
    <!-- VK Widget -->
    <div id="vk_community_messages"></div>
    <Girl/>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import Footer from '@/components/Footer.vue';
import Girl from '@/components/Girl.vue';

export default {
  name: 'App',
  components: {
    Logo,
    Footer,
    Girl,
  },
  mounted() {
    window.VK.Widgets.CommunityMessages('vk_community_messages', 200164769, { disableButtonTooltip: '1' });
    // window.VK.Widgets.Group('vk_groups', {
    //   mode: 4,
    //   width: 'auto',
    //   height: '400',
    //   color1: '121212',
    //   color2: 'FFFFFF',
    //   color3: 'FF521E',
    // }, 200164769);
  },
};
</script>

<style lang="sass">
@import './assets/css/main.sass';

@font-face
  font-family: "Montserrat"
  src: url("./assets/fonts/Montserrat-Regular.ttf")
  font-display: swap
*
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
html
  height: 100%
body
  background-color: black
  background-image: url('./assets/img/background_new.jpg')
  background-repeat: no-repeat
  background-position-x: center
  background-position-y: top
  padding: 0px
  margin: 0px
  height: 100%

body::-webkit-scrollbar
  width: 2px

body::-webkit-scrollbar-track
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0)

body::-webkit-scrollbar-thumb
  background-color: rgba(255, 97, 48, 1) // #FF6130

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  min-width: 320px
  display: flex
  flex-direction: column
  height: 100%
  .header
    display: flex
    justify-content: center
  .content
    background: rgba(0,0,0,.6)
    padding: 20px 20px 20px 20px
    display: flex
    justify-content: center
    flex: 1 0 auto
  .footer
    flex: 0 0 auto
</style>

<style lang="sass">
  .el-notification__content p
    color: black !important
    text-align: left
</style>
