import axios from 'axios';
// import router from './router';
// import store from './store/index';

const PROD = true;
const API_URI = PROD ? 'https://cssoftshop.ru/backend_api/v1' : 'http://localhost:8088/v1';

export default {
  // getPayLink: (params) => axios.post(`${API_URI}/getPayLink`, params),
  getPaymentData: (params) => axios.post(`${API_URI}/getPaymentData`, params),
  getPaymentDataCripta: (params) => axios.post(`${API_URI}/openPaymentPage`, params),
};
