<template>
    <div @click="click" :class="['sliding-button', disabled ? 'disabled_btn': 'enabled_btn']">{{ text }}</div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      if (this.$props.disabled) {
        return;
      }
      this.$emit('click');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

  .sliding-button
    cursor: pointer
    text-decoration: none
    color: white
    display: inline-block
    position: relative
    // background-color: rgba(100,228,132,.2)
    padding: 15px 30px
    border: 1px solid
    border-image: linear-gradient(180deg, rgba(100,228,132,1) 0%, rgba(100,228,132,1) 100%)
    border-image-slice: 1
    // margin: 10px 20px
    text-transform: uppercase
    overflow: hidden
    letter-spacing: 2px
    transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)
    text-align: center

  .enabled_btn
    &:before
      content: ""
      position: absolute
      left: 0
      top: 0
      height: 0
      width: 100%
      z-index: -1
      color: white
      background: #67e787
      transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)

    &:hover
      background: rgba(255, 255, 255, 0)

      &:before
        bottom: 0%
        top: auto
        height: 100%

  .disabled_btn
    border: 1px solid gray
    background-color: rgba(150, 150, 150, 0.5)

</style>
