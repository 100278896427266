import { render, staticRenderFns } from "./SubscriptionType.vue?vue&type=template&id=639f9547&scoped=true"
import script from "./SubscriptionType.vue?vue&type=script&lang=js"
export * from "./SubscriptionType.vue?vue&type=script&lang=js"
import style0 from "./SubscriptionType.vue?vue&type=style&index=0&id=639f9547&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639f9547",
  null
  
)

export default component.exports