<template>
  <div class="girl" ref="girl">
    <img src="@/assets/img/girl.png" ref="girlImg"/>
  </div>
</template>

<script>
// import anime from 'animejs';

export default {
  name: 'Girl',
  data() {
    return {
      isShowGirl: true,
    };
  },
  methods: {},
  // mounted() {
  //   // this.$refs.girl.style.display = 'block';
  //   setTimeout(() => {
  //     this.$refs.girl.style.left = '60px';
  //     this.isShowGirl = true;
  //     anime({
  //       targets: this.$refs.girl,
  //       opacity: 1,
  //       duration: 1000,
  //       easing: 'easeInCubic',
  //     });
  //   }, 500);
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.girl
  position: fixed
  bottom: 0px
  left: 60px
  width: 324px
  height: 526px
  @media (max-width: 1600px)
    display: none !important
</style>
