<template>
  <router-link to="/" title="cssoftshop - Главная">
    <img class="logo" alt="logo" title="Чит для кс го" src="../assets/img/logo_modern.png"/>
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.logo
  max-width: 100%
</style>
