<template>
  <div class="subscription-type">
    <div v-for="(item, index) in list" :key="item.id"
      :class="[item.id === selectedId ? 'subscription-type__item_selected' : 'subscription-type__item' ]"
      @click="selectTypehandler(item.id)"
    >
      <!-- <img v-if="index === 0" class="sale-hit" src="@/assets/img/sale_hit.png" /> -->
      <div class="subscription-type__description">{{ item.title }}</div>
      <div class="subscription-type__price">
        <div v-if="item.oldPrice">
          <s style="color: gray">{{ item.oldPrice }}₽</s> <br/> {{ item.price }}₽
        </div>
        <div v-else>
          {{ item.price }}₽
        </div>
<!--        <div style="font-size: 14px"> {{ item.price_usd }} USDT</div>-->
      </div>
      <div class="subscription-type-header" v-if="index === 1">
        <div class="subscription-type-header__title">Популярно</div>
      </div>
      <div class="subscription-type-header" v-if="index === 2">
        <div class="subscription-type-header__title">Выгодно</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionType',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedId: Number,
  },
  data() {
    return {
      isBuyState: false,
    };
  },
  methods: {
    selectTypehandler(id) {
      this.$emit('select', id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

.sale-hit
  width: 50px
  position: absolute
  top: -15px
  left: -15px
.subscription-type
  display: flex
  justify-content: center
  flex-wrap: wrap

.subscription-type__item, .subscription-type__item_selected
  position: relative
  width: 150px
  height: 100px
  border: 1px solid #183485
  background-color: rgba(30, 30, 30, .8);
  display: flex
  flex-direction: column
  justify-content: center
  border-radius: 5px
  margin: 6px
  &:hover
    background-color: rgba(50, 50, 50, .8);
    cursor: pointer

.subscription-type__item_selected
  background-color: #2854d5
  &:hover
    background-color: #2854d5

.subscription-type__description
  font-size: 24px
  color: white
  font-weight: bold

.subscription-type__price
  font-size: 16px
  margin-top: 6px
  color: #71d771
  font-weight: bold

.subscription-type-header
  position: absolute
  top: -10px
  width: 100%
  display: flex
  justify-content: center
  &__title
    background-color: #32b332
    font-size: 14px
    color: white
    font-weight: bold
    width: 70%
    border-radius: 4px
    padding: 3px 0px
</style>
