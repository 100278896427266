<template>
  <div class="terms">
    <h1>Как распаковать архив</h1>
    <p><b>Вы можете распаковать архив любым удобным для вас способом.
      <br/>В данном руководстве представлен один из вариантов.</b></p>

    <p class="description"> Шаг 1. Дважды кликаем на скачанный файл(архив) и переходим в раздел "Извлечение"</p>
    <img class="guide_archive" alt="Как распаковать архив. Шаг 1" title="Как распаковать архив. Шаг 1"  src="../assets/img/guide/step1.png" />

    <p class="description"> Шаг 2. Нажимаем на "Извлечение все"</p>
    <img class="guide_archive" alt="Как распаковать архив. Шаг 2" title="Как распаковать архив. Шаг 2"  src="../assets/img/guide/step2.png" />

    <p class="description"> Шаг 3. Выбираем куда распаковать архив (в данном примере - на рабочий стол), <br/>
    оставляем галку "Показать извлеченные файлы" и жмем "Извлечь"</p>
    <img class="guide_archive" alt="Как распаковать архив. Шаг 3" title="Как распаковать архив. Шаг 3"  src="../assets/img/guide/step3.png" />

    <p class="description"> Шаг 4. Когда процесс завершится, откроется папка, куда был распакован архив. Запускаем .exe файл.<br/>
    <b>Внимание!</b> Все файлы должны лежать рядом (не нужно выносить .exe файл из папки)
    </p>
    <img class="guide_archive" alt="Как распаковать архив. Шаг 4" title="Как распаковать архив. Шаг 4"  src="../assets/img/guide/step4.png" />
  </div>
</template>

<script>

export default {
  name: 'Guide',
  metaInfo: {
    title: 'ВХ для КС - Инструкция как распаковать архив',
    link: [
      { rel: 'canonical', href: 'https://cssoftshop.ru/guide' },
    ],
    meta: [
      { name: 'description', content: 'Как распаковать архив' },
    ],
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

.description
    color: white
    text-align: left
    max-width: 900px
    margin: 10px auto

.guide_archive
  max-width: 100%
  height: auto

</style>
