<template>
    <div class="banner-container">
      <a href="https://truthordaregame.ru" target="_blank" title="Правда или Действие - игра в Telegram">
        <img class="big-banner" src="@/assets/img/banner_truth_or_dare.png" alt="Правда или Действие - игра в Telegram баннер"/>
        <img class="small-banner" src="@/assets/img/banner_truth_or_dare_small.png" alt="Правда или Действие - игра в Telegram баннер"/>
      </a>
    </div>
</template>

<script>
export default {
  name: 'Banner',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
  .banner-container
    width: 468px
    height: 150px
    @media (max-width: 1600px)
      width: 250px
      max-width: 100%
      height: 360px

  .big-banner
    @media (max-width: 1600px)
      display: none !important

  .small-banner
    @media (min-width: 1601px)
      display: none !important
</style>
